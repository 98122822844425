import { AbstractPosItem, PrivilegeItem } from './api';
export type MasterItem<T extends AbstractPosItem> = T & { isMoved: boolean; };

/** Transform AbstractPosItem to MasterItem. MasterItem has isMoved-field available */
export function masterItem<T extends AbstractPosItem>(item: T, childList: T[], privilege?: PrivilegeItem): MasterItem<T> {
  let masterItem = { ...item, isMoved: false };
  if (!privilege) {
    return masterItem;
  }
  if (privilege.type === 'CORPORATION') {
    masterItem.isMoved = !!childList.find(x => x.masterId === item.id && x.corporationId === privilege.id);
  } else if (privilege.type === 'BUSINESS_GROUP') {
    masterItem.isMoved = !!childList.find(x => x.masterId === item.id && x.businessGroupId === privilege.id);
  } else if (privilege.type === 'CLIENT') {
    masterItem.isMoved = !!childList.find(x => x.masterId === item.id && x.clientId === privilege.id);
  }
  return masterItem;
}
