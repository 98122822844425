import { ClientUpdateWindow, DayOfWeek, Repository } from "utils/api";

export interface UpdateWindow {
  weekday: DayOfWeek;
  startTime?: string;
  endTime?: string;
}

export const weekdayList: DayOfWeek[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

export class RepositoryUtil {
  public static wouldDowngrade(targetRepository: Repository, currentRepository?: Repository) {
    if (!targetRepository || !currentRepository) {
      return false;
    }
    if (currentRepository.branch === "master") {
      return false;
    }
    if (targetRepository.branch === "master") {
      return true;
    }
    return targetRepository.branch.localeCompare(currentRepository.branch, undefined, { numeric: true }) === -1;
  }

  public static updateWindowList(updateWindowList: ClientUpdateWindow[]): UpdateWindow[] {
    let list: UpdateWindow[] = [];
    for (let weekday of weekdayList) {
      list.push({ weekday, });
    }
    /* set server data to appropriate day slot */
    for (let frame of updateWindowList) {
      list[weekdayList.indexOf(frame.weekday)] = {
        weekday: frame.weekday,
        startTime: frame.startTime.replace(/:00$/, ""), /* avoid 1 second precision in UI */
        endTime: frame.endTime.replace(/:00$/, ""), /* avoid 1 second precision in UI */
      };
    }
    return list;
  }
}
