import { I18N } from "aurelia-i18n";
import { BindingSignaler } from 'aurelia-templating-resources';
import { Translate } from "./api";

/** Primary translations are stored in server, translations holds fallback / "default" translations */
export function reloadTranslations(bindingSignaler: BindingSignaler, i18n: I18N, translations: Translate[]) {
  i18n.i18next.removeResourceBundle("fi", "server");
  i18n.i18next.removeResourceBundle("en", "server");
  for (let tr of translations) {
    tr.fiValue != undefined && i18n.i18next.addResource("fi", "server", tr.key, tr.fiValue);
    tr.enValue != undefined && i18n.i18next.addResource("en", "server", tr.key, tr.enValue);
    i18n.i18next.addResource("fi", "translation", tr.key, tr.fiDefault || tr.key);
    i18n.i18next.addResource("en", "translation", tr.key, tr.enDefault || tr.key);
  }
  bindingSignaler.signal("aurelia-translation-signal");
}
