import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ClientUnitListResponse, MyHttpApi } from 'utils/api';
import env from '../../config/environment.json';

interface UIUnit extends ClientUnitListResponse {
  remoteControlView?: string;
}

@autoinject
export class UnitsList {
  private unitsList: UIUnit[] = [];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    let units = await this.api.unitList();
    this.unitsList = units.map(u => {
      let unit: UIUnit = {
        ...u,
        state: u.state == "UP" ? "UP" : "DOWN",
      };
      if (unit?.state == "UP" && unit.canRemoteControl) {
        unit.remoteControlView = env.server + "RemoteControl/view/" + unit.clientId + "/";
      }
      return unit;
    });
  }
  rowCall(key: string, id: number) {
    this.router.navigateToRoute('units/show', { id: "" + id });
  }
}
