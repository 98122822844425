import { autoinject } from "aurelia-framework";
import { FormatDateValueConverter } from 'resources/value-converters/format-date';

/** Supress the date-array to range-format to save space e.g.
 * start-end, singleDay, start2-end2 
 * "2022-04-02", "2022-04-04", "2022-04-05", "2022-04-06", "2022-04-07", "2022-04-08", "2022-04-09", "2022-04-10", "2022-04-11", "2022-04-12", "2022-04-13", "2022-04-14", "2022-04-15", "2022-04-16", "2022-04-17", "2022-04-18", "2022-04-19", "2022-04-20", "2022-04-21", "2022-04-22", "2022-04-23", "2022-04-24", "2022-04-25", "2022-04-26", "2022-04-27", "2022-04-28", "2022-04-29", "2022-04-30"
 * will be converter into
 * 2.4.2022, 4.4.2022 - 30.4.2022
 * "2022-06-01", "2022-06-02", "2022-06-03", "2022-06-04", "2022-06-05", "2022-06-06", "2022-06-08", "2022-06-09", "2022-06-10", "2022-06-11", "2022-06-12", "2022-06-13", "2022-06-14", "2022-06-15", "2022-06-16", "2022-06-17", "2022-06-18", "2022-06-19", "2022-06-20", "2022-06-21", "2022-06-22", "2022-06-23", "2022-06-24", "2022-06-25", "2022-06-26", "2022-06-27", "2022-06-28", "2022-06-29", "2022-06-30"
 * will be converted into
 * 1.6.2022 - 6.6.2022, 8.6.2022 - 30.6.2022
 */
@autoinject
export class DatesToRange {
  private formatDate = new FormatDateValueConverter;

  transform(dateList: Date[]) {
    let result = "";
    if (!dateList.length) {
      return result;
    }
    let start = this._comparableDate(dateList[0], 0);
    let previousDate: Date | undefined;
    for (const d of dateList) {
      const currentDate = this._comparableDate(d, 0);
      if (previousDate) {
        let isItPreviousDate = this._comparableDate(previousDate, 1);
        // If previous date was not fact day before current date, then start a new range + write results
        if (isItPreviousDate.getTime() !== currentDate.getTime()) {
          result += this._writeRange(result, start, previousDate);
          start = currentDate;
        }
      }
      previousDate = currentDate;
    }
    // write the last range, can be dateList[0] - dateList[dateList.length - 1]
    if (previousDate) {
      result += this._writeRange(result, start, previousDate);
    }
    return result;
  }

  _writeRange(result: string, start?: Date, previous?: Date) {
    let internalResult = "";
    // If we have previous range writte, separate by comma
    if (result) {
      internalResult += ", ";
    }
    // one day range, display as single value
    if (start?.getTime() === previous?.getTime()) {
      internalResult += this.formatDate.toView(start);
    // display as range
    } else {
      internalResult += this.formatDate.toView(start) + " - " + this.formatDate.toView(previous);
    }
    return internalResult;
  }

  // use UTC dates to avoid DST issues on March and October
  _comparableDate(d: Date, offset: number) {
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate() + offset));
  }
}
