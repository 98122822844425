import { BookkeepingCustomer, ClientBilling } from "utils/api";

export class BookkeepingUtil {
  /** Use standardized display name for Client-billing like elements */
  public static generateBillingDisplayName(cb: ClientBilling): string {
    const normalName = this.generateCustomerNormalName(cb);
    const officialName = cb.billingOfficialName;
    let displayName = cb.billingOfficialName || normalName;
    if (normalName && officialName && normalName != officialName) {
      displayName = officialName + " - " + normalName;
    }
    return displayName;
  }

  public static generateCustomerNormalName(cb: ClientBilling): string {
    let name = cb.billingName || "";
    if (cb.billingName2) {
      name += " - " + cb.billingName2;
    }
    return name;
  }

  public static generateCustomerDropdownName(customer: BookkeepingCustomer): string {
    let name = "";
    if (customer.name && customer.officialName && customer.name != customer.officialName) {
      name += customer.officialName + " - " + customer.name;
    } else {
      name += (customer.name || customer.officialName || "(ei nimeä)");
    }
    if (customer.name && customer.additionalName && customer.additionalName != customer.name) {
      name += " / " + customer.additionalName
    }
    if (customer.businessId) {
      name += " (" + customer.businessId + ")";
    }
    return name;

  }
}
