import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ActorWithAccessResponse, BusinessGroup, ClientUnit, Corporation, ModuleType, MyHttpApi } from 'utils/api';
import env from '../../config/environment.json';
import { FormatBooleanValueConverter } from 'resources/value-converters/format-boolean';

interface UIUnit extends ClientUnit {
  id: number;
  version: string;
  remoteControlEnabled: boolean;
  remoteControlView?: string;
  uiModules: UIModule[];
}

interface UIModule  {
  moduleType: ModuleType;
  enabled: string;
}

@autoinject
export class UnitsShow {
  private unit?: UIUnit;
  // @ts-ignore Fugly hack to get enum
  private moduleTypes: string[] = Object.keys(this.i18n.i18next.getDataByLanguage(this.i18n.getLocale()).translation["ModuleType"]);
  private businessGroup?: BusinessGroup;
  private corporation?: Corporation;
  private ownPermissions?: ActorWithAccessResponse;
  private actorsWithAccess: ActorWithAccessResponse[] = [];
  private formatBoolean = new FormatBooleanValueConverter;
  private showWaybill = false;

  constructor(private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string }) {
    const paramsId = parseInt(params.id);
    let [unit, showWaybill] = await Promise.all([
      this.api.unitById({ clientId: paramsId }),
      this.api.waybillCanLogin({ clientId: paramsId }),
    ]);
    this.showWaybill = showWaybill;
    
    if (unit.client.businessGroupId) {
      this.businessGroup = await this.api.businessGroupById({id: unit.client.businessGroupId});
      if (this.businessGroup.corporationId) {
        this.corporation = await this.api.corporationById({id: this.businessGroup.corporationId});
      }
    }
    let modules = this.moduleTypes.map(mt => {
      let cm: UIModule = {
        moduleType: <ModuleType> mt,
        enabled: this.formatBoolean.toView(!!unit.clientModule.find(x => x.moduleType == mt && !x.deleteTime)),
      }
      return cm;
    });
    this.unit = {
      ...unit,
      id: paramsId,
      state: unit.state == "UP" ? "UP" : "DOWN",
      version: unit.tuloposVersion || "-",
      remoteControlEnabled: unit.clientModule.some( cm => cm.moduleType === "RemoteControl"),
      uiModules: modules,
    };
    if (this.unit?.state == "UP" && this.unit.remoteControlEnabled) {
      this.unit.remoteControlView = env.server + "RemoteControl/view/" + unit.client.id + "/";
    }
    await this.refresh();
  }
  async refresh() {
    if (!this.unit?.id) {
      return;
    }
    if (this.api.session?.canCreateUsers || this.api.session?.canAdmin) {
      this.actorsWithAccess = await this.api.actorListWithAccessByClientId({id: this.unit.id});
      this.ownPermissions = this.actorsWithAccess.find(x => x.actorId === this.api.session?.actorId) || {
        actorId: this.api.session.actorId,
        username: this.api.session.username ,
        viaClient: false, 
        viaBusinessGroup: false,
        viaCorporation: false,
      };
    }
  }
  async removeClient(actorId: number) {
    if (!this.unit?.id) {
      return;
    }

    await this.api.actorRemoveOneClient( {actorId, clientId: this.unit.id});
    await this.refresh(); 
  }

  async removeBusinessGroup(actorId: number) {
    if (!this.unit?.client.businessGroupId) {
      return;
    }

    await this.api.actorRemoveOneBusinessGroup({ actorId, businessGroupId: this.unit.client.businessGroupId });
    await this.refresh();
  }
  
  async removeCorporation(actorId: number) {
    if (!this.businessGroup?.corporationId) {
      return;
    }

    await this.api.actorRemoveOneCorporation({ actorId, corporationId: this.businessGroup?.corporationId });
    await this.refresh();
  }

  canRemoveGroup(awa: ActorWithAccessResponse){
    if (!awa.viaBusinessGroup || awa.viaCorporation || this.api.session?.actorId === awa.actorId) {
      return false;
    }
    if (this.ownPermissions?.viaBusinessGroup || this.ownPermissions?.viaCorporation || this.api.session?.canAdmin) {
      return true;
    }
    return false;
  }

  canRemoveCorporation(awa: ActorWithAccessResponse){
    if (!awa.viaCorporation || this.api.session?.actorId === awa.actorId)  {
      return false;
    }
    if (this.ownPermissions?.viaCorporation || this.api.session?.canAdmin) {
      return true;
    }
    return false;
  }

  // really hacky but i have no clue how this framework works
  async loginToWaybill() {
	if(this.unit) {
		const {url} = await this.api.waybillLogin({clientId: this.unit.id});
		
		const element = document.getElementById("waybill-login-link") as HTMLAnchorElement;
		element.href = url;
		element.click();
	}
	return false;
  }

}
