// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.header {
  margin: 1em 0;
}
div.header a {
  font-size: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/units/show.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAGI,cAAA;AAEJ","sourcesContent":["div.header {\n  margin: 1em 0;\n  a {\n    font-size: 2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
