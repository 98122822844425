/**
 * input-date works at midnight UTC time, when it is operating as a date field (no time).
 * 
 * This approach removes local timezone handling from the date representation, so we
 * can safely chop T00:00:00.0Z off from the ISO8601 timestamp, and the remaining value shows
 * the same date. This rarely does anything in Finland, except during the time between
 * e.g. 00:00:00 - 02:00:00 when UTC date shows the prior date.
 * 
 * This helper utility converts the localdate portion of a Date object into UTC at midnight.
 * 
 * @param date 
 * @returns same date portion, but UTC time set to midnight.
 */
export function sameLocalDateAtMidnightUtc(date: Date) {
	let millis = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
	return new Date(millis);
}

/** Transform date to isoFormat, does not care of time zone */
export function localDateToIsoString(date: Date): string {
	const y = date.getFullYear();
	const m = pad(date.getMonth() + 1);
	const d = pad(date.getDate());
	return `${y}-${m}-${d}`;
}

function pad(part: number): string {
	const prefix = part.toString().length === 1 ? "0" : "";
	return prefix + part.toString();
}

/** Parse ISO-date to localDate */
export function isoStringToDate(date: string): Date {
	const parts = date.split("-").map(x => parseInt(x));
	return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], 0, 0, 0));
}

export class DateCache {
	static startDate?: Date;
	static endDate?: Date;
	static startOfMonth(date?: Date) {
		let lastMonth = date || new Date();
		lastMonth.setDate(1);
		return lastMonth;
	}

	static endOfMonth(date?: Date) {
		let lastMonth = date || new Date();
		lastMonth.setDate(1);
		let endOfLastMonth = new Date(lastMonth);
		endOfLastMonth.setMonth(endOfLastMonth.getMonth() + 1);
		endOfLastMonth.setDate(endOfLastMonth.getDate() - 1);
		return endOfLastMonth;
	}
};