import { autoinject } from "aurelia-framework";
export type QuickMonthType = "THIS" | "LAST";

@autoinject
export class QuickMonth {
    getDates(quickMonth?: QuickMonthType) {
        if(! quickMonth) {
            return [undefined, undefined];
        }
        const d = new Date();
        if (quickMonth === "LAST") {
            d.setMonth(d.getMonth() - 1, 1);
        }
        const startDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
        const endDate = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
        return [startDate, endDate];
    }
}
