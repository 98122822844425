const MAX_ITERATIONS = 20;

export class EventuallyCorrectSearch<T, U> {
  private searching = false;

  constructor(private getSearchArgs: () => T, private searchFunc: (params: T) => Promise<U>, private applyData: (data: U) => void) {
  }

  delay(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async search() {
    if (this.searching) {
      return;
    }
    this.searching = true;

    for (let i = MAX_ITERATIONS; i > 0; i--) {
      /* Prevent very rapid search spamming @ searchFunc; also coalesce potentially multiple updates into one before triggering the first search. */
      await this.delay(20);

      let searchArgs = this.getSearchArgs();
      let searchArgsStr = JSON.stringify(searchArgs);
      try {
        let data = await this.searchFunc(searchArgs);
        this.applyData(data);
      } catch (e) {
        this.searching = false;
        throw e;
      }

      /* Is current search result still current? */
      let nextSearchArgs = this.getSearchArgs();
      if (JSON.stringify(nextSearchArgs) === searchArgsStr) {
        break;
      }

      if (i === 1) {
        console.warn("Ran out of eventually consistent search iterations before search arguments were stable; showing latest iteration. Consider adding debounce: 300 to value.bind");
      }
    }

    this.searching = false;
  }
}
